import Works from "../Component/Works";
import AboutUs from "../Component/AboutUs";
import AddSliderImage from "../Component/Add/AddSliderImage";
import SliderImages from "../Component/SliderImages";
import fontawesome from '@fortawesome/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressCard,faPalette,faUserCircle,faTachometerAlt,faEnvelope} from '@fortawesome/free-solid-svg-icons'
import AddWork from "../Component/Add/AddWork";
import AddAboutUs from "../Component/Add/AddAboutUs";
import UpdateSliderImage from "../Component/Update/UpdateSliderImage";
import UpdateWork from "../Component/Update/UpdateWork";
import UpdateAboutUs from "../Component/Update/UpdateAboutUs";
import Contact from "../Component/Contact";
import Animations from "../Component/Animations";
import VirtualWorks from "../Component/VirtualWorks";
import AddAnimation from "../Component/Add/AddAnimation";
import AddVirtualWork from "../Component/Add/AddVirtualWork";
import UpdateAnimation from "../Component/Update/UpdateAnimation";
import UpdateVirtualWork from "../Component/Update/UpdateVirtualWork";
import ArModels from "../Component/ArModels";
import AddArModel from "../Component/Add/AddArModel";
import UpdateArMoel from "../Component/Update/UpdateArMoel";
fontawesome.library.add( faAddressCard,faPalette,faUserCircle,faTachometerAlt,faEnvelope)

export const routes = [
    {
        path: '/sliderimages',
        exact: false,
        component: <SliderImages />,
        title: 'Slider Images',
        sidebar: true,
        icon:<FontAwesomeIcon icon={"tachometer-alt"} />
    },
    {
        path: '/works',
        exact: false,
        component: <Works />,
        title: 'Works',
        sidebar: true,
        icon:<FontAwesomeIcon icon={"address-card"} />

    },
    {
        path: '/aboutUs',
        exact: false,
        component: <AboutUs />,
        title: 'About Us',
        sidebar: true,
        icon:<FontAwesomeIcon icon={"palette"} />
    },
    {
        path: '/animations',
        exact: false,
        component: <Animations />,
        title: 'Animations',
        sidebar: true,
        icon:<FontAwesomeIcon icon={"address-card"} />
    },
    {
        path: '/virtualWorks',
        exact: false,
        component: <VirtualWorks />,
        title: 'Virtual Walk',
        sidebar: true,
        icon:<FontAwesomeIcon icon={"address-card"} />
    },
    {
        path: '/arModels',
        exact: false,
        component: <ArModels />,
        title: 'AR Models',
        sidebar: true,
        icon:<FontAwesomeIcon icon={"address-card"} />
    },
    {
        path: '/contactInfo',
        exact: false,
        component: <Contact />,
        title: 'Contact',
        sidebar: true,
        icon:<FontAwesomeIcon icon={"envelope"} />
    },
    {
        path: '/add/sliderImage',
        exact: false,
        component: <AddSliderImage />,
        title: 'Users',
        sidebar: false,
        icon:<FontAwesomeIcon icon={"user-circle"} />
    },
    {
        path: '/add/work',
        exact: false,
        component: <AddWork />,
        title: 'Users',
        sidebar: false,
        icon:<FontAwesomeIcon icon={"user-circle"} />
    },
    {
        path: '/add/aboutUs',
        exact: false,
        component: <AddAboutUs />,
        title: 'Users',
        sidebar: false,
        icon:<FontAwesomeIcon icon={"user-circle"} />
    },
    {
        path: '/add/animation',
        exact: false,
        component: <AddAnimation />,
        title: 'Users',
        sidebar: false,
        icon:<FontAwesomeIcon icon={"user-circle"} />
    },
    {
        path: '/add/virtualWork',
        exact: false,
        component: <AddVirtualWork />,
        title: 'Users',
        sidebar: false,
        icon:<FontAwesomeIcon icon={"user-circle"} />
    },
    {
        path: '/add/armodel',
        exact: false,
        component: <AddArModel />,
        title: 'Users',
        sidebar: false,
        icon:<FontAwesomeIcon icon={"user-circle"} />
    },
    {
        path: '/update/slider/:id',
        exact: false,
        component: <UpdateSliderImage />,
        title: 'Users',
        sidebar: false,
        icon:<FontAwesomeIcon icon={"user-circle"} />
    },
    {
        path: '/update/work/:id',
        exact: false,
        component: <UpdateWork />,
        title: 'Users',
        sidebar: false,
        icon:<FontAwesomeIcon icon={"user-circle"} />
    },
    {
        path: '/update/aboutUs/:id',
        exact: false,
        component: <UpdateAboutUs />,
        title: 'Users',
        sidebar: false,
        icon:<FontAwesomeIcon icon={"user-circle"} />
    },
    {
        path: '/update/animation/:id',
        exact: false,
        component: <UpdateAnimation />,
        title: 'Users',
        sidebar: false,
        icon:<FontAwesomeIcon icon={"user-circle"} />
    },
    {
        path: '/update/virtualWork/:id',
        exact: false,
        component: <UpdateVirtualWork />,
        title: 'Users',
        sidebar: false,
        icon:<FontAwesomeIcon icon={"user-circle"} />
    },
    {
        path: '/update/armodel/:id',
        exact: false,
        component: <UpdateArMoel />,
        title: 'Users',
        sidebar: false,
        icon:<FontAwesomeIcon icon={"user-circle"} />
    },
]