import React, { Component } from "react";
import fontawesome from "@fortawesome/fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Axios from "axios";
import { API } from "../Constants/Constant";
import Notification from "./Notification";
fontawesome.library.add(faPencilAlt, faEye, faTrash);
const pages = [5, 10, 25, 50, 100];

export default class Themes extends Component {
  state = {
    aboutUs: [],
    prevPageUrl: null,
    nextPageUrl: null,
    perPage: 10,
    empty:false,
    showNoti:false,
    notiTitle:'',
    notiColor:'',
  };

  componentDidMount() {
    this.getAllAboutUs("all");
  }

  getAllAboutUs = (request) => {
    const all = API.getURLs.aboutUs + "?perPage=" + this.state.perPage;
    const prev = this.state.prevPageUrl + "&perPage=" + this.state.perPage;
    const next = this.state.nextPageUrl + "&perPage=" + this.state.perPage;

    Axios.get(request === "all" ? all : request === "next" ? next : prev).then(
      (res) => {
        //console.log(res.data);
        if (res.data.status === "success") {
          this.setState({
            aboutUs: res.data.aboutUs.data,
            nextPageUrl: res.data.aboutUs.next_page_url,
            prevPageUrl: res.data.aboutUs.prev_page_url,
            empty:false
          });
        } else {
          this.setState({
            notiColor: 'red', showNoti: true, notiTitle:res.data.message
          });
          window.scroll(0,0);
          setTimeout(() => {
            this.setState({showNoti: false})
          }, 2000);
        }
      }
    );
  };

  chngStatus = (e, id) => {
    Axios.post(API.postURLs.aboutUsStatus + id, {
      status: e.target.value,
    }).then((res) => {
      if (res.data.status === "success") {
        this.getAllAboutUs("all");
      }
    });
  };

  fetchPrevPage = () => this.getAllAboutUs('prev')
 
  fetchNextPage = () => this.getAllAboutUs('next')
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    }, ()=>{
      this.getAllAboutUs('all')
    });
  }

  destroyAbout = (id) =>{
   var confirm = window.confirm("Are you sure you want to delete this record ?");
    if(confirm === true){
      Axios.post(API.postURLs.aboutdelete+id).then(res=>{
      //  console.log(res);
        if(res.data.status==='success'){
          this.setState({
            notiColor:'green',notiTitle:res.data.message,showNoti:true
          });
          window.scroll(0,0);
          setTimeout(() => {
            this.setState({showNoti: false})
          }, 2000);
        }else{
          this.setState({
            notiColor:'red',notiTitle:res.data.message,showNoti:true
          });
          window.scroll(0,0);
          setTimeout(() => {
            this.setState({showNoti: false})
          }, 2000);
        }
        this.getAllAboutUs('all');
      });
    }else{
      this.getAllAboutUs('all');
    }
   
  }

  render() {
    return (
      <div>
        {this.state.showNoti?
        <Notification title={this.state.notiTitle} bgColor={this.state.notiColor}/>
      :null}
        <div className="card">
          <div className="cardHeader">
            <h3>About Us</h3>
            <Link className="btn button" to="/add/aboutUs">
              Add About Us
            </Link>
          </div>
        </div>
        <div className="card">
          <div>
            <select
              name="perPage"
              id="perPage"
              value={this.state.perPage}
              onChange={this.handleChange}
            >
              {pages.map((page, index) => (
                <option key={index} value={page}>
                  {page}
                </option>
              ))}
            </select>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Sr.No.</th>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {this.state.aboutUs.map((about, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{about.title}</td>
                    <td> <div dangerouslySetInnerHTML={{__html:about.description}} /></td>
                    <td>
                      <select
                        onChange={(e) => {
                          this.chngStatus(e, about.id);
                        }}
                        className={
                          about.status === "active"
                            ? "btn btn-sm btn-success"
                            : "btn btn-sm btn-danger"
                        }
                        id="status"
                        value={about.status}
                        name="status"
                      >
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </td>
                    <td>
                      <Link
                        to={"/update/aboutUs/" + about.id}
                        className="btn btn-sm btn-info"
                      >
                        <FontAwesomeIcon icon={"pencil-alt"} />
                      </Link> <button onClick={()=>{
                        this.destroyAbout(about.id)
                      }} className="btn btn-sm btn-warning">
                        <FontAwesomeIcon icon={"trash"} color="#fff" />
                      </button >
                    </td>
                  </tr>
                ))}
                {this.state.empty ? this.state.aboutUs.length === 0 ? <h3>Data not found</h3>:null:null}
              </tbody>
            </table>
            <div className="col-md-3">
              <button className='btn' disabled={this.state.prevPageUrl ? false:true} style={{opacity:this.state.prevPageUrl ? 1: 0.5}} onClick={this.fetchPrevPage}>Prev</button>
              <button className='btn' disabled={this.state.nextPageUrl?false:true} style={{opacity:this.state.nextPageUrl ? 1 : 0.5}} onClick={this.fetchNextPage}>Next</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
