import React, { Component } from "react";
import { Link } from "react-router-dom";
import fontawesome from "@fortawesome/fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import Notification from "./Notification";
import { API } from "../Constants/Constant";
import Axios from "axios";
fontawesome.library.add(faPencilAlt, faEye, faTrash);
const pages = [5, 10, 25, 50, 100];

export default class Animations extends Component {
  state = {
    animations: [],
    videoPath: "",
    nextPageUrl: null,
    lastPageUrl: null,
    perPage: 10,
    empty: false,
    showNoti: false,
    notiTitle: "",
    notiColor: "",
  };

  componentDidMount() {
    this.getAllAnimations("all");
  }

  getAllAnimations = (request) => {
    const all = API.getURLs.animations + "?perPage=" + this.state.perPage;
    const prev = this.state.prevPageUrl + "&perPage=" + this.state.perPage;
    const next = this.state.nextPageUrl + "&perPage=" + this.state.perPage;

    Axios.get(request === "all" ? all : request === "next" ? next : prev).then(
      (res) => {
        console.log(res);
        if (res.data.status === "success") {
          this.setState({
            animations: res.data.animations.data,
            videoPath: res.data.videoPath,
            nextPageUrl: res.data.animations.next_page_url,
            prevPageUrl: res.data.animations.prev_page_url,
            empty: true,
          });
        } else {
          this.setState({
            notiColor: "red",
            showNoti: true,
            notiTitle: res.data.message,
          });
          setTimeout(() => {
            this.setState({ showNoti: false });
          }, 2000);
        }
      }
    );
  };

  chngStatus = (e, id) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    Axios.post(API.postURLs.animationStatus + id, {
      status: e.target.value,
    }).then((res) => {
      if (res.data.status === "success") {
        this.getAllAnimations("all");
      }
    });
  };

  fetchPrevPage = () => this.getAllAnimations("prev");

  fetchNextPage = () => this.getAllAnimations("next");
  handleChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        this.getAllAnimations("all");
      }
    );
  };

  destroyAnimation = (id) => {
    var confirm = window.confirm("Are you sure you want to delete this record ?");
    if(confirm === true){
      Axios.post(API.postURLs.animationDelete + id).then((res) => {
        if (res.data.status === "success") {
          this.setState({
            notiColor: "green",
            notiTitle: res.data.message,
            showNoti: true,
          });
          window.scroll(0,0);
          setTimeout(() => {
            this.setState({ showNoti: false });
          }, 2000);
        }else{
          this.setState({
            notiColor:'red',notiTitle:res.data.message,showNoti:true
          });
          window.scroll(0,0);
          setTimeout(() => {
            this.setState({showNoti: false})
          }, 2000);
        }
        this.getAllAnimations("all");
      });
    }else{
      this.getAllAnimations("all");
    }
    
  };

  render() {
    return (
      <div>
        {this.state.showNoti ? (
          <Notification
            title={this.state.notiTitle}
            bgColor={this.state.notiColor}
          />
        ) : null}
        <div className="card">
          <div className="cardHeader">
            <h3>Animations</h3>
            <Link className="btn button" to="add/animation">
              Add Animation
            </Link>
          </div>
        </div>
        <div className="card">
          <div>
            <select
              name="perPage"
              id="perPage"
              value={this.state.perPage}
              onChange={this.handleChange}
            >
              {pages.map((page, index) => (
                <option key={index} value={page}>
                  {page}
                </option>
              ))}
            </select>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Sr.No.</th>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Video</th>
                  <th>Thumbnail</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {this.state.animations.map((animation, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{animation.title === "null" ? null :animation.title}</td>
                    <td>
                      {animation.description === "null"
                        ? null
                        : animation.description}
                    </td>
                    <td>
                      <iframe
                        title="embed-test"
                        src={this.state.videoPath + "/" + animation.video}
                        width="200px"
                      ></iframe>
                    </td>
                    <td>
                       {
                        animation.image_thumbnail !==''
                         ? (<img src={this.state.videoPath +'/'+ animation.image_thumbnail}  width="150px" alt='thumbnail'/> ) : ''
                       }
                    </td>
                    
                    <td>
                      <select
                        onChange={(e) => {
                          this.chngStatus(e, animation.id);
                        }}
                        className={
                          animation.status === "active"
                            ? "btn btn-sm btn-success"
                            : "btn btn-sm btn-danger"
                        }
                        id="status"
                        value={animation.status}
                        name={"status-" + animation.id}
                      >
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </td>

                    <td>
                      <Link
                        to={"/update/animation/" + animation.id}
                        className="btn btn-sm btn-info"
                      > 
                      <FontAwesomeIcon icon={"pencil-alt"} />
                      </Link> <button
                        onClick={() => {
                          this.destroyAnimation(animation.id);
                        }}
                        className="btn btn-sm btn-warning"
                      >
                        <FontAwesomeIcon icon={"trash"} color="#fff" />
                      </button>
                    </td>
                  </tr>
                ))}
                {this.state.empty ? (
                  this.state.animations.length === 0 ? (
                    <h3>Data not found</h3>
                  ) : null
                ) : null}
              </tbody>
            </table>
            <div className="col-md-3">
              <button
                className="btn"
                disabled={this.state.prevPageUrl ? false : true}
                style={{ opacity: this.state.prevPageUrl ? 1 : 0.5 }}
                onClick={this.fetchPrevPage}
              >
                Prev
              </button>
              <button
                className="btn"
                onClick={this.fetchNextPage}
                disabled={this.state.nextPageUrl ? false : true}
                style={{ opacity: this.state.nextPageUrl ? 1 : 0.5 }}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
