import Axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { API } from "../../Constants/Constant";
import Notification from "../Notification";

export default class AddAnimation extends Component {
  state = {
    title: "",
    description: "",
    video: "",
    showNoti: false,
    notiTitle: "",
    notiColor: "",
    loader: false,
  };
  handleChange = (e) => {
    //console.log(e.target.name);
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        //console.log(this.state.title);
        console.log(this.state.description);
      }
    );
  };

  handleFile = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.files[0],
      },
      () => {}
    );
  };

  submitAnimation = (e) => {
    e.preventDefault();
    this.setState({loader: true})
    if (this.state.video) {
      var formData = new FormData();
      formData.append("title", this.state.title);
      formData.append("description", this.state.description);
      formData.append("video", this.state.video);
      formData.append("image_thumbnail", this.state.image_thumbnail);
      Axios.post(API.postURLs.addAnimation, formData)
        .then((res) => {
          // console.log(res.data);
          if (res.data.status === "success") {
            this.setState({
              title: "",
              description: "",
              video: "",image_thumbnail:'',
              showNoti: true,
              notiTitle: "Added Successfully",
              notiColor: "green",
              loader: false
            });
            window.scroll(0, 0);
            setTimeout(() => {
              this.setState({ showNoti: false });
              this.props.history.goBack();
            }, 2000);
            e.target.reset();
          } else {
            this.setState({
              notiColor: "red",
              showNoti: true,
              notiTitle: res.data,
              loader: false
            });
            window.scroll(0, 0);
            setTimeout(() => {
              this.setState({ showNoti: false });
            }, 2000);
          }
        })
        .catch((error) => {
          console.log(error.response);
          // this.setState({ title: "", description: "", video: "" ,notiColor: 'red', showNoti: true, notiTitle: error.response.data.errors.title[0] });
          // setTimeout(() => {
          //   this.setState({showNoti: false})
          // }, 2000);
          e.target.reset();
        });
    } else {
      this.setState({
        notiColor: "red",
        showNoti: true,
        notiTitle: "All fields are required.",
        loader: false
      });
      window.scroll(0, 0);
      setTimeout(() => {
        this.setState({ showNoti: false });
      }, 2000);
    }
  };

  render() {
    return (
      <div>
        {this.state.showNoti ? (
          <Notification
            title={this.state.notiTitle}
            bgColor={this.state.notiColor}
          />
        ) : null}
        <div className="card">
          <div className="cardHeader">
            <h3>Add Animation</h3>
          </div>
        </div>
        <div className="card">
          <form onSubmit={this.submitAnimation}>
            <div className="form-group col-md-6">
              <label htmlFor="title">Title:</label>
              <input
                type="text"
                className="form-control titlePicker"
                id="title"
                placeholder="Enter title"
                name="title"
                onChange={this.handleChange}
                value={this.state.title}
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="description">Description:</label>
              <textarea
                name="description"
                id="description"
                className="form-control"
                placeholder="Enter description"
                cols="30"
                rows="10"
                onChange={this.handleChange}
              ></textarea>
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="video">Video:</label>
              <input
                type="file"
                className="form-control"
                id="video"
                name="video"
                required
                accept="video/*"
                onChange={this.handleFile}
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="image_thumbnail">Image:</label>
              <input
                type="file"
                className="form-control"
                id="image_thumbnail"
                name="image_thumbnail"
                accept="image/*"
                onChange={this.handleFile}
              />
            </div>
            <div className="form-group col-md-4">
            {this.state.loader ? <p>Please wait while we updating your files.</p>:null}
              <button type="submit" className="btn btn-success">
              {this.state.loader ? 'Loading...':'Submit'}
              </button> <Link to="/animations" className="btn btn-danger">
                Cancel</Link>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
