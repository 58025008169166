import React, { Component } from 'react'
import fontawesome from '@fortawesome/fontawesome'
import {faUserCircle} from '@fortawesome/free-solid-svg-icons'
fontawesome.library.add(faUserCircle)

export default class Navbar extends Component {
    render() {
        return (
            <div className="navbar">
               <h1>Admin</h1>
             
            </div>
        )
    }
}
