//import logo from './logo.svg';
import React from 'react';
import {
  BrowserRouter as Router} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import MainContent from './Component/MainContent';
import Navbar from './Component/Navbar';
import Sidebar from './Component/Sidebar';
import Login from './Component/Login';
import Axios from 'axios';


class App extends React.Component{
  state={
    login: false
  }
  componentDidMount() {
    const token = localStorage.getItem('token');
    if(token){
      this.setState({login: true});
      Axios.defaults.headers.common["Authorization"] = token;
    }
  }
  
  render(){
    return(
      <Router>
        {
          this.state.login ?
      <>
      <Navbar />
      <Sidebar />
      <MainContent />
      </>
        :
        <Login  />
        }

    </Router>
    );
  }
}

export default App;
