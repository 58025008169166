import Axios from 'axios';
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { API } from '../../Constants/Constant';
import Notification from '../Notification'


export default class AddArModel extends Component {
    state={
        title: "",
        description: "",
        usdz_file: "",
        glb_file: "",
        poster_image: "",
        environment_image:"",
        exposure: "",
        shadow_intensity: "",
        ar_placement: "floor",
        showNoti: false,
        notiTitle: '',
        notiColor: '',
        loader: false,
    }

    handleChange = (e) => {
        this.setState(
          {
            [e.target.name]: e.target.value,
          },
          () => {
            console.log(this.state.description);
          }
        );
    };
    
    handleFile = (e) => {
        this.setState(
          {
            [e.target.name]: e.target.files[0],
          },
          () => {}
        );
    };

    submitArModel =(e)=>{
        e.preventDefault();
         this.setState({loader: true})
        if(this.state.usdz_file  && this.state.glb_file){
            var formData =new FormData();
            formData.append("title", this.state.title);
            formData.append("description", this.state.description);
           
            formData.append("usdz_file", this.state.usdz_file);
            formData.append("glb_file", this.state.glb_file);
            formData.append("poster_image", this.state.poster_image);
            formData.append("environment_image", this.state.environment_image);
            
            formData.append("exposure", this.state.exposure);

            formData.append("shadow_intensity", this.state.shadow_intensity);

            formData.append("ar_placement", this.state.ar_placement);

            Axios.post(API.postURLs.addArmodel, formData).then(res=>{
                //console.log(res.data);
                if(res.data.status ==='success'){
                    this.setState({ title: "", description: "", usdz_file: "",glb_file:'' ,poster_image:'',exposure:'',shadow_intensity:'',ar_placement:'',environment_image:'',showNoti: true, notiTitle: 'Added Successfully', notiColor: 'green', loader: false});
                    window.scroll(0,0);
                    setTimeout(() => {
                    this.setState({showNoti: false})
                    this.props.history.goBack();
                    }, 2000);
                    e.target.reset();
                }else{
                    //console.log("diididi");
                    this.setState({
                        notiColor: 'red', showNoti: true, notiTitle:res.data,
                        loader: false
                      });
                      window.scroll(0,0);
                      setTimeout(() => {
                        this.setState({showNoti: false})
                      }, 2000);
                }
            }).catch((error) => {
                console.log(error.response);
                // this.setState({ title: "", description: "", video: "" ,notiColor: 'red', showNoti: true, notiTitle: error.response.data.errors.title[0] });
                // setTimeout(() => {
                //   this.setState({showNoti: false})
                // }, 2000);
                e.target.reset();
            })
        }else{
            //console.log("hihi");
            this.setState({
                notiColor: 'red', showNoti: true, notiTitle:'All fields are required.'
              }); 
              window.scroll(0,0);
              setTimeout(() => {
                this.setState({showNoti: false})
              }, 2000);
        }
    }

    render() {
        return (
            <div>
            {
              this.state.showNoti ?
              <Notification  title={this.state.notiTitle} bgColor={this.state.notiColor}/>
              :null
            }
            <div className="card">
              <div className="cardHeader">
                <h3>Add AR Model </h3>
              </div>
            </div>
            <div className="card">
              <form onSubmit={this.submitArModel}>
                <div className="form-group col-md-6">
                  <label htmlFor="title">Title:</label>
                  <input
                    type="text"
                    className="form-control titlePicker"
                    id="title"
                    placeholder="Enter title"
                    name="title"
                    required
                    onChange={this.handleChange}
                    value={this.state.title}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="description">Description:</label>
                  <textarea 
                  name="description" 
                  id="description"
                  className="form-control"
                  placeholder="Enter description"
                   cols="30" rows="10" onChange={
                     this.handleChange
                   }></textarea>
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="usdz">USDZ File:</label>
                  <input
                    type="file"
                    className="form-control"
                    id="usdz"
                    name="usdz_file"
                    required
                    // accept="usdz/*"
                    onChange={this.handleFile}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="glb">GLB File:</label>
                  <input
                    type="file"
                    className="form-control"
                    id="glb"
                    name="glb_file"
                    required
                    // accept="glb/*"
                    onChange={this.handleFile}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="posterImg">Poster Image:</label>
                  <input
                    type="file"
                    className="form-control"
                    id="posterImg"
                    name="poster_image"
                    required
                    onChange={this.handleFile}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="environment_image">Environment Image:</label>
                  <input
                    type="file"
                    className="form-control"
                    id="environment_image"
                    name="environment_image"
                    required
                    onChange={this.handleFile}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="exposure">Exposure:</label>
                  <input
                    type="text"
                    className="form-control titlePicker"
                    id="exposure"
                    placeholder="Enter exposure"
                    name="exposure"
                    required
                    onChange={this.handleChange}
                    value={this.state.exposure}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="shadow_intensity">Shadow Intensity:</label>
                  <input
                    type="text"
                    className="form-control titlePicker"
                    id="shadow_intensity"
                    placeholder="Enter shadow intensity"
                    name="shadow_intensity"
                    required
                    onChange={this.handleChange}
                    value={this.state.shadow_intensity}
                  />
                </div>
                
                <div className="form-group col-md-6">
                  <label htmlFor="ar_placement">AR Placement:</label>
                  <select  className="form-control titlePicker"
                    id="ar_placement"
                    placeholder="Enter AR placement"
                    name="ar_placement"
                    required onChange={this.handleChange}>
                    <option value="floor">Floor</option>
                    <option value="wall">Wall</option>
                  </select>
                 
                </div>
                {this.state.loader ? <p>Please wait while we updating your files.</p>:null}
                <div className="form-group col-md-4">
                  <button type="submit" className="btn btn-success">
                    {this.state.loader ? 'Loading...':'Submit'}
                  </button> <Link to="/arModels" className="btn btn-danger">
                    Cancel
                  </Link>
                </div>
              </form>
            </div>
          </div>
        )
    }
}
