import Axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { API } from "../../Constants/Constant";
import Notification from "../Notification";


export default class UpdateVirtualWork extends Component {
    state={
        virtualId:this.props.match.params.id,
        title:'',
        description:'',
        video:'',
        showNoti:false,
        notiColor:'',
        notiTitle:'',
        videoPath:'',
        loader: false,
      }

      handleChange =(e) =>{
        this.setState({
          [e.target.name]:e.target.value
        },()=>{
        })
      }
    
      handleFile =(e) =>{
        this.setState({
          [e.target.name]:e.target.files[0]
        },()=>{}
        )
      }

      updateVirtualWork = (e) =>{
        e.preventDefault();
        this.setState({loader: true})
        var formData =new FormData();
        formData.append('title',this.state.title ==="null" ? null :this.state.title);
        formData.append('description',this.state.description ==='null'? null : this.state.description);
        if(this.state.video){
          formData.append('video',this.state.video);
        }

        if(this.state.image_thumbnail){
          formData.append('image_thumbnail',this.state.image_thumbnail);
        }

        Axios.post(API.postURLs.updateVirtualWork+this.state.virtualId, formData).then(res =>{
           // console.log(res);
            if(res.data.status === 'success'){
                this.setState({ title: "", description: "", video: "" ,image_thumbnail:'',showNoti: true, notiTitle: 'Updated Successfully', notiColor: 'green',loader: false});
                window.scroll(0,0);
                setTimeout(() => {
                this.setState({showNoti: false})
                this.props.history.goBack();
                }, 2000);
                e.target.reset();
            }else{
                this.setState({
                    notiTitle:res.data.message,notiColor:'red',showNoti:true,
                    loader: false,
                  });
                  window.scroll(0,0);
                  setTimeout(() => {
                    this.setState({showNoti: false})
                  }, 2000);
                
            }
        })
      }


      componentDidMount() {
        //console.log(this.props);
        Axios.get(API.getURLs.showVirtualWork+this.state.virtualId).then(
          res =>{
            if(res.data.status === 'success'){
              this.setState({
                title: res.data.virtualwork.title,
                description: res.data.virtualwork.description,
                showvideo: res.data.virtualwork.video,
                thumbnail: res.data.virtualwork.image_thumbnail,
                videoPath:res.data.videoUrl
              })
            }
          })
      }


    render() {
        return (
            <div>
                {
          this.state.showNoti ?
          <Notification  title={this.state.notiTitle} bgColor={this.state.notiColor}/>
          :null
        }
        <div className="card">
          <div className="cardHeader">
            <h3>Update Virtual Walk</h3>
          </div>
        </div>
        <div className="card">
          <form onSubmit={this.updateVirtualWork}>
            <div className="form-group col-md-4">
              <label htmlFor="title">Title:</label>
              <input
                type="title"
                className="form-control titlePicker"
                id="title"
                placeholder="Enter title"
                name="title"
                value={this.state.title ==='null' ? null :this.state.title}
                onChange={this.handleChange}
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="description">Description:</label>
              <textarea 
              name="description" 
              id="description"
              className="form-control"
              placeholder="Enter description"
               cols="30" rows="10" onChange={
                 this.handleChange
               }
               value={this.state.description === 'null'?null :this.state.description}></textarea>
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="video">Video:</label>
              <input
                type="file"
                className="form-control"
                id="video"
                accept="video/*"
                name="video"
                onChange={this.handleFile}
                />
                <iframe title="embed-test" src={this.state.videoPath+'/'+this.state.showvideo} width="200px"></iframe>
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="image_thumbnail">Image:</label>
              <input
                type="file"
                className="form-control"
                id="image_thumbnail"
                accept="image/*"
                name="image_thumbnail"
                onChange={this.handleFile}
                />
                <img src={this.state.videoPath+'/'+this.state.thumbnail}alt="" width="100px"/>
            </div>
            <div className="form-group col-md-4">
            {this.state.loader ? <p>Please wait while we updating your files.</p>:null}
              <button type="submit" className="btn btn-success">
              {this.state.loader ? 'Loading...':'Submit'}
              </button> <Link to="/virtualWorks" className="btn btn-danger">
                Cancel
              </Link>
            </div>
          </form>
        </div>
            </div>
        )
    }
}
