//export const baseURL = "http://192.168.0.120:8000";
//export const baseURL = "http://127.0.0.1:8000";
export const baseURL = "https://core.kre83d.com";
//export const baseURL = "https://admin.eratnd.com";
export const API = {
    getURLs:{
        sliderImages: baseURL+'/api/sliderImages',
        works: baseURL+'/api/works',
        aboutUs: baseURL+'/api/aboutUs',
        animations: baseURL+'/api/getAllAnimations',
        virtualWorks: baseURL+'/api/getVirtualWork',
        arModel: baseURL+'/api/getArmodel',
        showSlider : baseURL+'/api/showSlider/',
        showAboutUs : baseURL+'/api/showAboutUs/',
        showWork : baseURL+'/api/showWork/',
        showAnimation : baseURL+'/api/showAnimation/',
        showVirtualWork : baseURL+'/api/showVirtualWork/',
        showArmodel: baseURL+'/api/showArmodel/',
        getAllContact : baseURL+'/api/getAllContacts',
    },
    postURLs:{
        silderStatus : baseURL+'/api/slider/status/',
        workStatus : baseURL+'/api/work/status/',
        animationStatus : baseURL+'/api/status/animation/',
        virtualWorkStatus : baseURL+'/api/status/VirtualWork/',
        aboutUsStatus : baseURL+'/api/changeStatus/',
        arModelStatus : baseURL+'/api/status/arModel/',
        addAboutUs : baseURL+'/api/addAboutUs',
        addWork : baseURL+'/api/addWork',
        addSliderImages : baseURL+'/api/addSliderImages',
        addAnimation : baseURL+'/api/addAnimation',
        addVirtualWork : baseURL+'/api/addVirtualWork',
        addArmodel : baseURL+'/api/addArmodel',
        updateAboutUs : baseURL+'/api/updateAboutUs/',
        updateWork : baseURL+'/api/updateWork/',
        updateSliderImg : baseURL+'/api/updateSliderImg/',
        updateAnimation: baseURL+'/api/updateAnimation/',
        updateVirtualWork : baseURL+'/api/updateVirtualWork/',
        updateArModel : baseURL+'/api/updateArmodel/',
        silderdelete : baseURL+'/api/slider/delete/',
        workdelete : baseURL+'/api/work/delete/',
        aboutdelete : baseURL+'/api/destroy/',
        animationDelete : baseURL+'/api/destroy/animation/',
        VirtualDelete : baseURL+'/api/destroy/VirtualWork/',
        arModelDelete : baseURL+'/api/destroy/arModel/',
        login: baseURL+'/api/login',
        
    }
}