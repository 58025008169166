import React, { Component } from "react";

import { Link } from "react-router-dom";
import Axios from "axios";
import { API } from "../Constants/Constant";
import fontawesome from "@fortawesome/fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import Notification from "./Notification";
fontawesome.library.add( faPencilAlt, faEye, faTrash );
const pages = [ 5, 10, 25, 50, 100]

export default class SliderImages extends Component {
  state = {
    sliders: [],
    imgPath:'',
    nextPageUrl: null,
    lastPageUrl: null,
    perPage: 10,
    empty: false,
    showNoti: false,
    notiTitle: '',
    notiColor: ''
  };
  componentDidMount() {
    this.getSliders('all');
  }

  getSliders = (request) => {
    const all =  API.getURLs.sliderImages+'?perPage='+this.state.perPage;
    const prev = this.state.prevPageUrl+'&perPage='+this.state.perPage;
    const next = this.state.nextPageUrl+'&perPage='+this.state.perPage;
    Axios.get(request === 'all'? all : request === 'next'?next : prev).then((res) => {

      if (res.data.status === "success") {
        this.setState({ 
          sliders: res.data.sliderImages.data,
          imgPath: res.data.imagePath,
          nextPageUrl: res.data.sliderImages.next_page_url,
          prevPageUrl: res.data.sliderImages.prev_page_url,
          empty: true
        });
      } else {
        this.setState({
          notiColor: 'red', showNoti: true, notiTitle:res.data.message
        });
        window.scroll(0,0);
        setTimeout(() => {
          this.setState({showNoti: false})
        }, 2000);
      
      }
    });
  };
  
  chngStatus =(e,id) =>{
      //console.log(e.target.value,id, e.target.name);
      this.setState({
        [e.target.name]: e.target.value
      })
    Axios.post(API.postURLs.silderStatus+id, {status:e.target.value}).then(res =>{
        if(res.data.status === 'success'){
          this.getSliders('all');
      
          }
      })
  }
  fetchPrevPage = () => this.getSliders('prev')
 
  fetchNextPage = () => this.getSliders('next')
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    }, ()=>{
      this.getSliders('all')
    });
  }
  destroySlider = (id) =>{
    var confirm = window.confirm("Are you sure you want to delete this record ?");
    if(confirm === true){
      Axios.post(API.postURLs.silderdelete+id).then(res=>{
        if(res.data.status==='success'){
          this.setState({
            notiColor: 'green', showNoti: true, notiTitle:res.data.message
          });
          window.scroll(0,0);
          setTimeout(() => {
            this.setState({showNoti: false})
          }, 2000);
        }else{
          this.setState({
            notiColor:'red',notiTitle:res.data.message,showNoti:true
          });
          window.scroll(0,0);
          setTimeout(() => {
            this.setState({showNoti: false})
          }, 2000);
        }
        this.getSliders('all');
      })
    }else{
      this.getSliders('all');
    }
    
  }
  render() {
    return (
      <div>
        {this.state.showNoti?
         <Notification title={this.state.notiTitle} bgColor={this.state.notiColor}/>
        :null}
        <div className="card">
          <div className="cardHeader">
            <h3>Slider Images</h3>
            <Link className='btn button' to="add/sliderImage">Add Slider Image</Link>
          </div>
        </div>
        <div className="card">
          <div>
            <select name="perPage" id="perPage" value={this.state.perPage} onChange={this.handleChange}>
              {pages.map((page, index)=>(
              <option key={index} value={page}>{page}</option>
            ))}
            </select>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Sr.No.</th>
                  <th>Sequence</th>
                  <th>Image</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                this.state.sliders.map((sliderImg, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{sliderImg.sequence}</td>
                    <td>
                      <img
                        src={this.state.imgPath+'/'+sliderImg.slider_image}
                        alt={sliderImg.slider_image}
                        width="100px"
                      />
                    </td>
                    <td>
                      <select
                        onChange={(e) => {
                          this.chngStatus(e, sliderImg.id);
                        }}
                        className={
                          sliderImg.status === "active"
                            ? "btn btn-sm btn-success"
                            : "btn btn-sm btn-danger"
                        }
                        id="status"
                        value={sliderImg.status}
                        name={"status-"+sliderImg.id}
                      >
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </td>

                    <td>
                      <Link to={"/update/slider/"+sliderImg.id} className="btn btn-sm btn-info">
                        <FontAwesomeIcon icon={"pencil-alt"} />
                      </Link> <button onClick={()=>{
                        this.destroySlider(sliderImg.id)
                      }} className="btn btn-sm btn-warning">
                        <FontAwesomeIcon icon={"trash"} color="#fff" />
                      </button>
                    </td>
                  </tr>
                ))
                }
                {this.state.empty ? this.state.sliders.length === 0 ? <h3>Data not found</h3>:null:null}
              </tbody>
            </table>
            <div className="col-md-3">
              <button className="btn" disabled={this.state.prevPageUrl? false: true} style={{opacity: this.state.prevPageUrl? 1: 0.5}} onClick={this.fetchPrevPage}>Prev</button>
              <button className="btn" onClick={this.fetchNextPage} 
              disabled={this.state.nextPageUrl? false: true} style={{opacity: this.state.nextPageUrl? 1: 0.5}} 
              >Next</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
