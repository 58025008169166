import Axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { API } from "../../Constants/Constant";
import Notification from "../Notification";

export default class AddSliderImage extends Component {
  state = {
    sequence: "",
    slider_image: "",
    showNoti: false,
    notiTitle: '',
    notiColor: '',
    loader: false,
  };

  handleChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {}
    );
  };
  handleFile = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.files[0],
      },
      () => {}
    );
  };
  submitCategory = (e) => {
    e.preventDefault();
    this.setState({loader: true})
    if (this.state.sequence && this.state.slider_image) {
      var formData = new FormData();
      formData.append("sequence", this.state.sequence);
      formData.append("sliderImage", this.state.slider_image);

      Axios.post(API.postURLs.addSliderImages, formData)
        .then((res) => {
          if (res.data.status === "success") {
            this.setState({ sequence: "", slider_image: "", showNoti: true, notiTitle: 'Added Successfully', notiColor: 'green',loader: false });
            window.scroll(0,0);
           setTimeout(() => {
             this.setState({showNoti: false})
             this.props.history.goBack();
           }, 2000);
          }else{
            this.setState({
              notiColor: 'red', showNoti: true, notiTitle:res.data,loader: false
            });
            window.scroll(0,0);
            setTimeout(() => {
              this.setState({showNoti: false})
            }, 2000);
      
          }
        }).catch((error) => {
          e.target.reset();
          this.setState({ sequence: "", slider_image: "", notiColor: 'red', showNoti: true,loader: false, notiTitle:  error.response.data.errors.sequence[0]});
          window.scroll(0,0);
          setTimeout(() => {
            this.setState({showNoti: false})
          }, 2000);
        });
    } else {
      this.setState({
        notiColor: 'red', showNoti: true, notiTitle:'All fields are required.',loader: false
      });
      window.scroll(0,0);
      setTimeout(() => {
        this.setState({showNoti: false})
      }, 2000);
    }
  };
  render() {
    return (
      <div>
        {this.state.showNoti?
         <Notification title={this.state.notiTitle} bgColor={this.state.notiColor}/>
        :null}
        <div className="card">
          <div className="cardHeader">
            <h3>Add Slider Image</h3>
          </div>
        </div>
        <div className="card">
          <form onSubmit={this.submitCategory}>
            <div className="form-group col-md-6">
              <label htmlFor="sequence">Sequence:</label>
              <input
                type="text"
                className="form-control"
                id="sequence"
                placeholder="Enter sequence"
                name="sequence"
                required
                onChange={this.handleChange}
                value={this.state.sequence}
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="slider_image">Image:</label>
              <input
                type="file"
                className="form-control"
                id="slider_image"
                name="slider_image"
                required
                onChange={this.handleFile}
                accept="image/*"
                //value={this.state.slider_image}
              />
            </div>
            <div className="form-group col-md-6">
            {this.state.loader ? <p>Please wait while we updating your files.</p>:null}
              <button type="submit" className="btn btn-success">
              {this.state.loader ? 'Loading...':'Submit'}
              </button> <Link to="/sliderimages" className="btn btn-danger">
                Cancel
              </Link>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
