import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { routes } from '../Routes/routes'
import fontawesome from '@fortawesome/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock} from '@fortawesome/free-solid-svg-icons'
fontawesome.library.add(faLock)
export default class Sidebar extends Component {
    logout=()=>{
        localStorage.clear();
        window.location.href= "/"
    }
    render() {
        return (
            <div className="sidebar">
                <div className="overlay"></div>
                <ul>
                    {routes.map((route, index)=> route.sidebar?
                         <NavLink exact={true} activeClassName="active" key={index} to={route.path}> <li>{route.icon}<span className="sidebarLabel">{route.title}</span></li></NavLink>
                : null)}
                  <li className="logoutBtn" onClick={this.logout}><FontAwesomeIcon icon={"lock"} /> <span className="sidebarLabel">Log Out</span></li>
                </ul>
            </div>
        )
    }
}
