import Axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { API } from "../../Constants/Constant";
import Notification from "../Notification";

export default class UpdateSliderImage extends Component {
  state = {
    sliderId: this.props.match.params.id,
    sequence: "",
    slider_image: "",
    sliderImg: "",
    imgPath: "",
    showNoti:false,
    notiColor:'',
    notiTitle:'',
    loader: false,
  };
  handleChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        ////console.log(this.state.title);
      }
    );
  };
  handleFile = (e) => {
    //console.log(e.target.files[0]);
    this.setState(
      {
        [e.target.name]: e.target.files[0],
      },
      () => {}
    );
  };

  updateSliderImages = (e) => {
    e.preventDefault();
    this.setState({loader: true})
    var formData = new FormData();
    formData.append("sequence", this.state.sequence);
    if (this.state.slider_image) {
      formData.append("sliderImage", this.state.slider_image);
    }

    Axios.post(API.postURLs.updateSliderImg + this.state.sliderId, formData)
      .then((res) => {
        //console.log(res);
        if (res.data.status === "success") {
          this.setState({ sequence: "", slider_image: "" ,showNoti: true, notiTitle: 'Updated Successfully', notiColor: 'green' , loader: false,});
          window.scroll(0,0);
          setTimeout(() => {
            this.setState({showNoti: false})
            this.props.history.goBack();
          }, 2000);
        } else {
         //alert(res.data.message);
          this.setState({
            notiTitle:res.data.message,notiColor:'red',showNoti:true, loader: false,
          });
          window.scroll(0,0);
          setTimeout(() => {
            this.setState({showNoti: false})
          }, 2000);
        }
      })
      .catch((error) => {
        console.log(error.response);
       // alert(error.response.data.errors.sliderImage[0]);
        e.target.reset();
        this.setState({ slider_image: "",notiColor: 'red', showNoti: true, notiTitle: error.response.data.errors.sliderImage[0]});
        window.scroll(0,0);
        setTimeout(() => {
          this.setState({showNoti: false})
        }, 2000);
      });
  };

  componentDidMount() {
    //console.log(this.props);
    Axios.get(API.getURLs.showSlider + this.state.sliderId).then((res) => {
      //console.log(res)
      if (res.data.status === "success") {
        this.setState({
          sequence: res.data.slider.sequence,
          sliderImg: res.data.slider.image,
          imgPath: res.data.imagePath,
        });
      }
    });
  }

  render() {
    return (
      <div>
        {
          this.state.showNoti ?
          <Notification title={this.state.notiTitle} bgColor={this.state.notiColor}/>:null

        }
        <div className="card">
          <div className="cardHeader">
            <h3>Update Slider Image</h3>
          </div>
        </div>
        <div className="card">
          <form onSubmit={this.updateSliderImages}>
            <div className="form-group col-md-6">
              <label htmlFor="sequence">Sequence:</label>
              <input
                type="sequence"
                className="form-control"
                id="sequence"
                required
                placeholder="Enter sequence"
                name="sequence"
                value={this.state.sequence}
                onChange={this.handleChange}
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="slider_image">Image:</label>
              <input
                type="file"
                className="form-control"
                id="slider_image"
                name="slider_image"
                accept="image/*"
                onChange={this.handleFile}
              />
              <img
                src={this.state.imgPath + "/" + this.state.sliderImg}
                alt=""
                width="100px"
              />
            </div>
            <div className="form-group col-md-6">
            {this.state.loader ? <p>Please wait while we updating your files.</p>:null}
              <button type="submit" className="btn btn-success">
              {this.state.loader ? 'Loading...':'Submit'}
              </button> <Link to="/sliderimages" className="btn btn-danger">
                Cancel
              </Link>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
