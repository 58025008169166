import Axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { API } from "../../Constants/Constant";
import Notification from "../Notification";

export default class UpdateWork extends Component {
  state={
    workId:this.props.match.params.id,
    title:'',
    description:'',
    image:'',
    showNoti:false,
    notiColor:'',
    notiTitle:'',
    loader: false,
  }

  handleChange =(e) =>{
    this.setState({
      [e.target.name]:e.target.value
    },()=>{
    })
  }

  handleFile =(e) =>{
    this.setState({
      [e.target.name]:e.target.files[0]
    },()=>{}
    )
  }

  updateWork =(e) =>{
    e.preventDefault();
    this.setState({loader: true})
    //console.log(this.state.description ==='null'? null : this.state.description);
    var formData =new FormData();
    formData.append('title',this.state.title==='null' ? null:this.state.title);
    formData.append('description',this.state.description ==='null'? null : this.state.description);
    if(this.state.image){
      formData.append('image',this.state.image);
    }

    Axios.post(API.postURLs.updateWork + this.state.workId,formData).then(res =>{
      if(res.data.status==='success'){
        this.setState({title:'',description:'',image:'',showNoti: true, notiTitle: 'Updated Successfully', notiColor: 'green',loader: false,})
        window.scroll(0,0);
        setTimeout(() => {
        this.setState({showNoti: false})
        this.props.history.goBack();
        }, 2000);
        e.target.reset();
      }else{
        this.setState({
          notiTitle:res.data.message,notiColor:'red',showNoti:true,loader: false,
        });
        window.scroll(0,0);
        setTimeout(() => {
          this.setState({showNoti: false})
        }, 2000);
      }
    }).catch(error =>{
     console.log(error.response);
     // console.clear();
    })
    
  }
  componentDidMount() {
    //console.log(this.props);
    Axios.get(API.getURLs.showWork+this.state.workId).then(
      res =>{
        //console.log(res);
        if(res.data.status === 'success'){
          this.setState({
            title: res.data.work.title,
            description: res.data.work.description,
            image: res.data.work.image,
            imgPath:res.data.imagePath
          
          })
        }
      })
  }


  render() {
    return (
      <div>
        {
          this.state.showNoti ?
          <Notification  title={this.state.notiTitle} bgColor={this.state.notiColor}/>
          :null
        }
        <div className="card">
          <div className="cardHeader">
            <h3>Update Work</h3>
          </div>
        </div>
        <div className="card">
          <form onSubmit={this.updateWork}>
            <div className="form-group col-md-4">
              <label htmlFor="title">Title:</label>
              <input
                type="title"
                className="form-control titlePicker"
                id="title"
                placeholder="Enter title"
                name="title"
                value={this.state.title === 'null'?null :this.state.title}
                onChange={this.handleChange}
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="description">Description:</label>
              <textarea 
              name="description" 
              id="description"
              className="form-control"
              placeholder="Enter description"
               cols="30" rows="10" onChange={
                 this.handleChange
               }
               value={this.state.description === 'null'?null :this.state.description}></textarea>
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="image">Image:</label>
              <input
                type="file"
                className="form-control"
                id="image"
                accept="image/*"
                name="image"
                onChange={this.handleFile}
                />
                <img src={this.state.imgPath+'/'+this.state.image}alt="" width="100px"/>
            </div>
            <div className="form-group col-md-4">
            {this.state.loader ? <p>Please wait while we updating your files.</p>:null}
              <button type="submit" className="btn btn-success">
              {this.state.loader ? 'Loading...':'Submit'}
              </button> <Link to="/works" className="btn btn-danger">
                Cancel
              </Link>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
