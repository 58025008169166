import Axios from "axios";
import React, { Component } from "react";

import { API } from "../Constants/Constant";
import Notification from "./Notification";
export default class Login extends Component {
  state = {
    email: "",
    password: "",
    showNoti: false,
    notiTitle: "",
    notiColor: "",
  };

  handleChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        //console.log(this.state.email);
        //console.log(this.state.password);
      }
    );
  };

  login = (e) => {
    e.preventDefault();
    if (this.state.email && this.state.password) {
      const payload = {
        email: this.state.email,
        password: this.state.password,
      };

      Axios.post(API.postURLs.login, payload)
        .then((res) => {
          //console.log(res);
          if (res.data.status === "success") {
            localStorage.setItem("token", "Bearer " + res.data.token);
            this.setState({ email: "", password: "" });
            window.location.href = "/sliderimages";
          } else {
            alert(res.error);
          }
        })
        .catch((error) => {
          // alert(error.response.data.message);
          this.setState({
            notiColor: "red",
            showNoti: true,
            notiTitle: error.response.data.message,
          });
          setTimeout(() => {
            this.setState({ showNoti: false });
          }, 2000);
        });
    } else {
      this.setState({
        notiColor: "red",
        showNoti: true,
        notiTitle: "All fields are required.",
      });
      setTimeout(() => {
        this.setState({ showNoti: false });
      }, 2000);
    }
  };

  render() {
    return (
      <div className={"loginWrapper"}>
        {
          this.state.showNoti ?
          <Notification title={this.state.notiTitle} bgColor={this.state.notiColor}/>:null

        }
        <div className="cardWrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className={"fullHeight"}>
                  <div className="formWrapper">
                    <h3 className="secondary">Welcome To</h3>
                    <h1 className="logo">Kre83d</h1>
                    <p className="tagLine secondary">
                      Portraying Unbuilt Architecture
                    </p>
                    <p>Log in</p>
                    <form onSubmit={this.login}>
                      <div className="form-group col-md-12">
                        <label htmlFor="email">Email:</label>
                        <input
                          type="email"
                          className="form-control titlePicker"
                          id="title"
                          placeholder="Enter Email"
                          name="email"
                          onChange={this.handleChange}
                          value={this.state.email}
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <label htmlFor="password">Password:</label>
                        <input
                          type="password"
                          className="form-control titlePicker"
                          id="password"
                          placeholder="Enter password"
                          name="password"
                          onChange={this.handleChange}
                          value={this.state.password}
                        />
                      </div>
                      <div className={"col-md-12"}>
                        <button className={"btn"}>Submit</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-md-6 background">
                <div className={"fullHeight"}>
                  <div className="overlay"></div>
                  <div className="verticalCenter">
                    <h1 className="logo">Kre83d</h1>

                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Accusamus architecto facere aspernatur officia itaque
                      natus minima hic, ducimus sunt ullam possimus, iure
                      consequatur harum provident ipsum. Laudantium, placeat?
                      Hic, mollitia?
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
