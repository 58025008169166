import React, { Component } from 'react'
import Axios from "axios";
import { Link } from "react-router-dom";
import { API } from "../../Constants/Constant";
import Notification from "../Notification";

export default class UpdateArMoel extends Component {
    state = {
        arModelId: this.props.match.params.id,
        title: "",
        description: "",
        usdz_file: "",
        glb_file: "",
        poster_image: "",
        environment_image: "",
        exposure: "",
        shadow_intensity: "",
        ar_placement: "floor",
        showNoti: false,
        notiColor: "",
        notiTitle: "",
        path: "",
        loader: false,
      };

      handleChange = (e) => {
        this.setState(
          {
            [e.target.name]: e.target.value,
          },
          () => {}
        );
      };
    
      handleFile = (e) => {
        this.setState(
          {
            [e.target.name]: e.target.files[0],
          },
          () => {}
        );
      };
      updateArModel =(e)=>{
        e.preventDefault();
        this.setState({loader: true})
        var formData = new FormData();
        formData.append("title", this.state.title ==='null'? null :this.state.title);
        formData.append("description",this.state.description === "null" ? null : this.state.description
        );

        if (this.state.usdz_file) {
            formData.append("usdz_file", this.state.usdz_file);
        }

        if (this.state.glb_file) {
            formData.append("glb_file", this.state.glb_file);
        }
      
        if (this.state.poster_image) {
            formData.append("poster_image", this.state.poster_image);
          
        }

        if (this.state.environment_image) {
          formData.append("environment_image", this.state.environment_image);
        
      }
        

        formData.append("exposure", this.state.exposure ==='null'? null :this.state.exposure);
        formData.append("shadow_intensity", this.state.shadow_intensity ==='null'? null :this.state.shadow_intensity);
        formData.append("ar_placement", this.state.ar_placement);

        Axios.post(API.postURLs.updateArModel+this.state.arModelId,formData).then((res)=>{
            if(res.data.status ==='success'){
                this.setState({showNoti: true, notiTitle: 'Updated  Successfully', notiColor: 'green',loader: false});
                window.scroll(0,0);
                setTimeout(() => {
                this.setState({showNoti: false})
                // this.props.history.goBack();
                }, 2000);
                e.target.reset();
            }else{
                //console.log("diididi");
                this.setState({
                    notiColor: 'red', showNoti: true, notiTitle:res.data,
                    loader: false
                  });
                  window.scroll(0,0);
                  setTimeout(() => {
                    this.setState({showNoti: false})
                  }, 2000);
            }
        }).catch(error =>{
            console.log(error);
        })
      
      }

      componentDidMount() {
          //console.log(this.props);
            Axios.get(API.getURLs.showArmodel+this.state.arModelId).then(
                (res) => {
               // console.log(res);
                    if (res.data.status === "success") {
                        this.setState({
                        title: res.data.arModel.title,
                        description: res.data.arModel.description,
                        usdzFile: res.data.arModel.usdz_file,
                        glbFile: res.data.arModel.glb_file,
                        posterImg: res.data.arModel.poster_image,
                        envImg: res.data.arModel.environment_image,
                        exposure: res.data.arModel.exposure,
                        shadow_intensity: res.data.arModel.shadow_intensity,
                        ar_placement: res.data.arModel.ar_placement,
                        path: res.data.url,
                        });
                    }
                }
            );
      }
      
    render() {
        return (
            <div>
            {this.state.showNoti ? (
              <Notification
                title={this.state.notiTitle}
                bgColor={this.state.notiColor}
              />
            ) : null}
            <div className="card">
              <div className="cardHeader">
                <h3>Update AR Model</h3>
              </div>
            </div>
            <div className="card">
              <form onSubmit={this.updateArModel}>
                <div className="form-group col-md-4">
                  <label htmlFor="title">Title:</label>
                  <input
                    type="title"
                    className="form-control titlePicker"
                    id="title"
                    placeholder="Enter title"
                    name="title"
                    value={this.state.title === 'null' ? null : this.state.title}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="description">Description:</label>
                  <textarea
                    name="description"
                    id="description"
                    className="form-control"
                    placeholder="Enter description"
                    cols="30"
                    rows="10"
                    onChange={this.handleChange}
                    value={
                      this.state.description === "null"
                        ? null
                        : this.state.description
                    }
                  ></textarea>
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="usdz">USDZ File:</label>
                  <input
                    type="file"
                    className="form-control"
                    id="usdz"
                    name="usdz_file"
                    // accept="usdz/*"
                    onChange={this.handleFile}
                  />
                 <label>{this.state.usdzFile!='' ? this.state.usdzFile : ''}</label>
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="glb">GLB File:</label>
                  <input
                    type="file"
                    className="form-control"
                    id="glb"
                    name="glb_file"
                    // accept="glb/*"
                    onChange={this.handleFile}
                  />
                    <label>{this.state.glbFile!='' ? this.state.glbFile : ''}</label>
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="posterImg">Poster Image:</label>
                  <input
                    type="file"
                    className="form-control"
                    id="posterImg"
                    name="poster_image"
                    onChange={this.handleFile}
                  />

                <img src={this.state.path+'/'+this.state.posterImg}alt="" width="100px"/>
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="envImg">Enviornnment Image:</label>
                  <input
                    type="file"
                    className="form-control"
                    id="envImg"
                    name="environment_image"
                  
                    onChange={this.handleFile}
                  />

                <img src={this.state.path+'/'+this.state.envImg}alt="" width="100px"/>
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="exposure">Exposure:</label>
                  <input
                    type="text"
                    className="form-control titlePicker"
                    id="exposure"
                    placeholder="Enter exposure"
                    name="exposure"
                   
                    onChange={this.handleChange}
                    value={this.state.exposure === 'null' ? null : this.state.exposure}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="shadow_intensity">Shadow Intensity:</label>
                  <input
                    type="text"
                    className="form-control titlePicker"
                    id="shadow_intensity"
                    placeholder="Enter shadow intensity"
                    name="shadow_intensity"
                   
                    onChange={this.handleChange}
                    value={this.state.shadow_intensity === 'null' ? null : this.state.shadow_intensity}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="ar_placement">AR Placement:</label>
                  <select  className="form-control titlePicker"
                    id="arPlacement"
                    placeholder="Enter AR placement"
                    name="ar_placement"
                    value={this.state.ar_placement}
                     onChange={this.handleChange}>
                    <option value="floor">Floor</option>
                    <option value="wall">Wall</option>
                  </select>
                 
                </div>
               
                <div className="form-group col-md-4">
                {this.state.loader ? <p>Please wait while we updating your files.</p>:null}
                  <button type="submit" className="btn btn-success">
                  {this.state.loader ? 'Loading...':'Submit'}
                  </button> <Link to="/arModels" className="btn btn-danger">
                    Cancel
                  </Link>
                </div>
              </form>
            </div>
          </div>
        )
    }
}
