import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AboutUs from "./AboutUs";
import SliderImages from "./SliderImages";
import Works from "./Works";
import AddSliderImage from './Add/AddSliderImage';
import AddWork from "./Add/AddWork";
import AddAboutUs from "./Add/AddAboutUs";
import UpdateSliderImage from "./Update/UpdateSliderImage";
import UpdateWork from "./Update/UpdateWork";
import UpdateAboutUs from "./Update/UpdateAboutUs";
import Contact from "./Contact";
import Animations from "./Animations";
import VirtualWorks from "./VirtualWorks";
import AddAnimation from "./Add/AddAnimation";
import AddVirtualWork from "./Add/AddVirtualWork";
import UpdateAnimation from "./Update/UpdateAnimation";
import UpdateVirtualWork from "./Update/UpdateVirtualWork";
import ArModels from "./ArModels";
import AddArModel from "./Add/AddArModel";
import UpdateArMoel from "./Update/UpdateArMoel";
export default class MainContent extends React.Component {

  render() {
    return (
      <div className="mainContent container-fluid">
        <div className="container">
         
          <Switch>
             <Route path={'/sliderimages'}
                render={(props)=><SliderImages {...props} />}
                exact={true}
              />
              <Route path={'/works'}
                render={(props)=><Works {...props} />}
                exact={true}
              />
              <Route path={'/aboutUs'}
                render={(props)=><AboutUs {...props} />}
                exact={true}
              />
              <Route path={'/animations'}
                render={(props)=><Animations {...props} />}
                exact={true}
              />
               <Route path={'/arModels'}
                render={(props)=><ArModels {...props} />}
                exact={true}
              />
              <Route path={'/virtualWorks'}
                render={(props)=><VirtualWorks {...props} />}
                exact={true}
              />
                <Route path={'/contactInfo'}
                render={(props)=><Contact {...props} />}
                exact={true}
              />
              <Route path={'/add/sliderImage'}
                render={(props)=> <AddSliderImage {...props}/>}
                exact={true}
              />
              <Route path={'/add/work'}
                render={(props)=> <AddWork {...props}/>}
                exact={true}
              />
              <Route path={'/add/aboutUs'}
                render={(props)=> <AddAboutUs {...props}/>}
                exact={true}
              />
               <Route path={'/add/animation'}
                render={(props)=> <AddAnimation {...props}/>}
                exact={true}
              />
               <Route path={'/add/virtualwork'}
                render={(props)=> <AddVirtualWork {...props}/>}
                exact={true}
              />
               <Route path={'/add/armodel'}
                render={(props)=> <AddArModel {...props}/>}
                exact={true}
              />
              <Route path={'/update/slider/:id'}
                render={(props)=> <UpdateSliderImage {...props}/>}
                exact={true}
              />
              <Route path={'/update/work/:id'}
                render={(props)=> <UpdateWork {...props}/>}
                exact={true}
              />
              <Route path={'/update/aboutUs/:id'}
                render={(props)=> <UpdateAboutUs {...props}/>}
                exact={true}
              />
              <Route path={'/update/animation/:id'}
                render={(props)=> <UpdateAnimation {...props}/>}
                exact={true}
              />
               <Route path={'/update/virtualWork/:id'}
                render={(props)=> <UpdateVirtualWork {...props}/>}
                exact={true}
              />
               <Route path={'/update/armodel/:id'}
                render={(props)=> <UpdateArMoel {...props}/>}
                exact={true}
              />
            <Redirect to="/sliderimages" from="/" />
              
          </Switch>
          </div>
        </div>
    );
  }
}
