import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Notification from './Notification';
import fontawesome from "@fortawesome/fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { API } from "../Constants/Constant";
import Axios from "axios";
fontawesome.library.add(faPencilAlt, faTrash);
const pages = [5, 10, 25, 50, 100];

export default class VirtualWorks extends Component {
    state = {
        virtualWork: [],
        videoPath: "",
        nextPageUrl: null,
        lastPageUrl: null,
        perPage: 10,
        empty: false,
        showNoti: false,
        notiTitle: "",
        notiColor: "",
      };
    
      componentDidMount() {
        this.getVirtualWorks("all");
      }
    
      getVirtualWorks = (request) => {
        const all = API.getURLs.virtualWorks + "?perPage=" + this.state.perPage;
        const prev = this.state.prevPageUrl + "&perPage=" + this.state.perPage;
        const next = this.state.nextPageUrl + "&perPage=" + this.state.perPage;
    
        Axios.get(request === "all" ? all : request === "next" ? next : prev).then(
          (res) => {
          //console.log(res.data.virtualWork.data);
            if (res.data.status === "success") {
              this.setState({
                virtualWork: res.data.virtualWork.data,
                videoPath: res.data.videoPath,
                nextPageUrl: res.data.virtualWork.next_page_url,
                prevPageUrl: res.data.virtualWork.prev_page_url,
                empty: true,
              });
            } else {
              this.setState({
                notiColor: "red",
                showNoti: true,
                notiTitle: res.data.message,
              });
              setTimeout(() => {
                this.setState({ showNoti: false });
              }, 2000);
            }
          }
        );
      };
    
      chngStatus = (e, id) => {
        //console.log(e.target.value,id, e.target.name);
        this.setState({
          [e.target.name]: e.target.value,
        });
        Axios.post(API.postURLs.virtualWorkStatus + id, {
          status: e.target.value,
        }).then((res) => {
          if (res.data.status === "success") {
            this.getVirtualWorks("all");
          }
        });
      };
    
      fetchPrevPage = () => this.getVirtualWorks("prev");
    
      fetchNextPage = () => this.getVirtualWorks("next");
      handleChange = (e) => {
        this.setState(
          {
            [e.target.name]: e.target.value,
          },
          () => {
            this.getVirtualWorks("all");
          }
        );
      };
    
      destroyVirtualWork = (id) => {
        var confirm = window.confirm("Are you sure you want to delete this record ?");
      if(confirm === true){
        Axios.post(API.postURLs.VirtualDelete + id).then((res) => {
          if (res.data.status === "success") {
            this.setState({
              notiColor: "green",
              notiTitle: res.data.message,
              showNoti: true,
            });
            window.scroll(0,0);
            setTimeout(() => {
              this.setState({ showNoti: false });
            }, 2000);
          }else{
            this.setState({
              notiColor:'red',notiTitle:res.data.message,showNoti:true
            });
            window.scroll(0,0);
            setTimeout(() => {
              this.setState({showNoti: false})
            }, 2000);
          }
          this.getVirtualWorks("all");
        });
      }else{
        this.getVirtualWorks("all");
      }
        
      };
    
    render() {
        return (
            <div>
            {this.state.showNoti?
             <Notification title={this.state.notiTitle} bgColor={this.state.notiColor}/>
            :null}
            <div className="card">
              <div className="cardHeader">
                <h3>Virtual Walk</h3>
                <Link className='btn button' to="add/virtualWork">Add Virtual Walk</Link>
              </div>
            </div>
            <div className="card">
              <div>
                <select name="perPage" id="perPage" value={this.state.perPage} onChange={this.handleChange}>
                  {pages.map((page, index)=>(
                  <option key={index} value={page}>{page}</option>
                ))}
                </select>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Sr.No.</th>
                      <th>Title</th>
                      <th>Description</th>
                      <th>Video</th>
                      <th>Thumbnail</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody> 
                    {
                    this.state.virtualWork.map((virtualWork, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{virtualWork.title === 'null'? null  : virtualWork.title}</td>
                        <td>{virtualWork.description === 'null'?null:virtualWork.description}</td>
                        <td>
                        <iframe title="embed-test" src={this.state.videoPath+'/'+virtualWork.video} width="200px"></iframe>
                          
                        </td>
                        <td>
                       {
                        virtualWork.image_thumbnail !==''
                         ? (<img src={this.state.videoPath +'/'+ virtualWork.image_thumbnail}  width="150px" alt='thumbnail'/> ) : ''
                       }
                    </td>
                        <td>
                          <select
                            onChange={(e) => {
                              this.chngStatus(e, virtualWork.id);
                            }}
                            className={
                              virtualWork.status === "active"
                                ? "btn btn-sm btn-success"
                                : "btn btn-sm btn-danger"
                            }
                            id="status"
                            value={virtualWork.status}
                            name={"status-"+virtualWork.id}
                          >
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </select>
                        </td>
    
                        <td>
                          <Link to={"/update/virtualWork/"+virtualWork.id} className="btn btn-sm btn-info">
                            <FontAwesomeIcon icon={"pencil-alt"} />
                          </Link> <button onClick={()=>{
                            this.destroyVirtualWork(virtualWork.id)
                          }} className="btn btn-sm btn-warning">
                            <FontAwesomeIcon icon={"trash"} color="#fff" />
                          </button>
                        </td>
                      </tr>
                    ))
                    }
                    {this.state.empty ? this.state.virtualWork.length === 0 ? <h3>Data not found</h3>:null:null}
                  </tbody>
                </table>
                <div className="col-md-3">
                  <button className="btn" disabled={this.state.prevPageUrl? false: true} style={{opacity: this.state.prevPageUrl? 1: 0.5}} onClick={this.fetchPrevPage}>Prev</button>
                  <button className="btn" onClick={this.fetchNextPage} 
                  disabled={this.state.nextPageUrl? false: true} style={{opacity: this.state.nextPageUrl? 1: 0.5}} 
                  >Next</button>
                </div>
              </div>
            </div>
          </div>
        )
    }
}
