import React, { Component } from 'react'

export default class Notification extends Component {
    render() {
        return (
            <div className='notificationWrapper' style={{backgroundColor: this.props.bgColor}}>
                <p>{this.props.title}</p>
            </div>
        )
    }
}
