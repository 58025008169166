import Axios from 'axios';
import React, { Component } from 'react'
import { API } from '../Constants/Constant';
const pages = [5, 10, 25, 50, 100]

export default class Contact extends Component {
    state={
        contact:[],
        prePageUrl:null,
        nextPageUrl:null,
        perPage: 10,
        empty:false
    }
    componentDidMount() {
        ////console.log(this.props);
        this.getContacts('all');
    }

    getContacts = (request) =>{
        const all = API.getURLs.getAllContact +'?perPage=' + this.state.perPage;

        const prev = this.state.prevPageUrl+ '&perPage=' + this.state.prevPage;
        
        const next = this.state.nextPageUrl+'&perPage=' + this.state.perPage;

        Axios.get(request === 'all' ? all : request === 'next' ? next : prev).then(res =>{
            //console.log(res);
            if(res.data.status === 'success'){
                this.setState({
                    contact: res.data.contacts.data,
                    nextPageUrl: res.data.contacts.next_page_url,
                    prevPageUrl: res.data.contacts.prev_page_url,
                    empty:true
                })
            }else{
                alert("failed");
            }
            
        })

    }

    fetchPrevPage = () => this.getContacts('prev')
 
    fetchNextPage = () => this.getContacts('next')
    handleChange = (e) => {
        this.setState({
          [e.target.name]: e.target.value
        }, ()=>{
          this.getContacts('all')
        });
      }
    render() {
        return (
            <div>
                <div className="card">
          <div className="cardHeader">
            <h3>Contact</h3>
          </div>
        </div>
        <div className="card">
          <div>
            <select
              name="perPage"
              id="perPage"
              value={this.state.perPage}
              onChange={this.handleChange}
            >
              {pages.map((page, index) => (
                <option key={index} value={page}>
                  {page}
                </option>
              ))}
            </select>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Sr.No.</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Contact No.</th>
                  <th>Subject</th>
                  <th>Message</th>
                </tr>
              </thead>
              <tbody>
                {this.state.contact.map((info, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{info.name}</td>
                    <td> {info.email}</td>
                    <td>{info.contact_no}</td>
                    <td>{info.subject}</td>
                    <td><div dangerouslySetInnerHTML={{__html:info.message}} /></td>
                  </tr>
                ))}
                {this.state.empty ? this.state.contact.length === 0 ? <h3>Data not found</h3>:null:null}
              </tbody>
            </table>
            <div className="col-md-3">
              <button className='btn' disabled={this.state.prevPageUrl ? false:true} style={{opacity:this.state.prevPageUrl ? 1: 0.5}} onClick={this.fetchPrevPage}>Prev</button>
              <button className='btn' disabled={this.state.nextPageUrl?false:true} style={{opacity:this.state.nextPageUrl ? 1 : 0.5}} onClick={this.fetchNextPage}>Next</button>
            </div>
          </div>
        </div>
            </div>
        )
    }
}
