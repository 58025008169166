import React, { Component } from 'react'
import { Link } from "react-router-dom";
import fontawesome from "@fortawesome/fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import Notification from "./Notification";
import { API } from "../Constants/Constant";
import Axios from "axios";
import CryptoJS from "crypto-js";
const encKey = "rkeday1288120"
fontawesome.library.add(faPencilAlt, faEye, faTrash);
const pages = [5, 10, 25, 50, 100];

export default class ArModels extends Component {
    state = {
        arModels: [],
        url: "",
        nextPageUrl: null,
        lastPageUrl: null,
        perPage: 10,
        empty: false,
        showNoti: false,
        notiTitle: "",
        notiColor: "",
      };

      encrypt = (id) => CryptoJS.AES.encrypt(id, encKey).toString();

      componentDidMount() {
    this.getAllArModels("all");
  }
    
  getAllArModels = (request) => {
    const all = API.getURLs.arModel + "?perPage=" + this.state.perPage;
    const prev = this.state.prevPageUrl + "&perPage=" + this.state.perPage;
    const next = this.state.nextPageUrl + "&perPage=" + this.state.perPage;

    Axios.get(request === "all" ? all : request === "next" ? next : prev).then(
      (res) => {
        console.log(res.data.arModel);
        if (res.data.status === "success") {
          this.setState({
            arModels: res.data.arModel.data,
            url: res.data.path,
            nextPageUrl: res.data.arModel.next_page_url,
            prevPageUrl: res.data.arModel.prev_page_url,
            empty: true,
          });
        } else {
          this.setState({
            notiColor: "red",
            showNoti: true,
            notiTitle: res.data.message,
          });
          setTimeout(() => {
            this.setState({ showNoti: false });
          }, 2000);
        }
      }
    );
  };

  chngStatus = (e, id) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    Axios.post(API.postURLs.arModelStatus + id, {
      status: e.target.value,
    }).then((res) => {
      if (res.data.status === "success") {
        this.getAllArModels("all");
      }
    });
  };

  fetchPrevPage = () => this.getAllArModels("prev");

  fetchNextPage = () => this.getAllArModels("next");
  handleChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        this.getAllArModels("all");
      }
    );
  };

  destroyarModel = (id) => {
    var confirm = window.confirm("Are you sure you want to delete this record ?");
    if(confirm === true){
      Axios.post(API.postURLs.arModelDelete + id).then((res) => {
        if (res.data.status === "success") {
          this.setState({
            notiColor: "green",
            notiTitle: res.data.message,
            showNoti: true,
          });
          window.scroll(0,0);
          setTimeout(() => {
            this.setState({ showNoti: false });
          }, 2000);
          this.getAllArModels("all");
        }else{
          this.setState({
            notiColor:'red',notiTitle:res.data.message,showNoti:true
          });
          window.scroll(0,0);
          setTimeout(() => {
            this.setState({showNoti: false})
          }, 2000);
        }
        this.getAllArModels("all");
      });
    }else{
      this.getAllArModels("all");
    }
    
  };

    render() {
        return (
            <div>
            {this.state.showNoti ? (
              <Notification
                title={this.state.notiTitle}
                bgColor={this.state.notiColor}
              />
            ) : null}
            <div className="card">
              <div className="cardHeader">
                <h3>AR Models</h3>
                <Link className="btn button" to="add/armodel">
                  Add AR Models
                </Link>
              </div>
            </div>
            <div className="card">
              <div>
                <select
                  name="perPage"
                  id="perPage"
                  value={this.state.perPage}
                  onChange={this.handleChange}
                >
                  {pages.map((page, index) => (
                    <option key={index} value={page}>
                      {page}
                    </option>
                  ))}
                </select>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Sr.No.</th>
                      <th>Title</th>
                      <th>Description</th>
                      <th>USDZ File</th>
                      <th>GLB File</th>
                      <th>Poster Image</th>
                      <th>Environment Image</th>
                      <th>AR Placement</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.arModels.map((arModel, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{arModel.title === "null" ? null :arModel.title}</td>
                        <td>
                          {arModel.description === "null"
                            ? null
                            : arModel.description}
                        </td>
                        <td>
                          {arModel.usdz_file !=='' ? 'File Uploded' : 'Not Uploaded'}
                        </td>
                        <td>
                        {arModel.glb_file !=='' ? 'File Uploded' : 'Not Uploaded'}
                        </td>
                        <td>
                           {
                            arModel.poster_image !==''
                             ? (<img src={this.state.url +'/'+ arModel.poster_image}  width="150px" alt='thumbnail'/> ) : ''
                           }
                        </td>
                        <td>
                           {
                            arModel.environment_image !=''
                             ? (<img src={this.state.url +'/'+ arModel.environment_image}  width="150px" alt='envImg'/> ) : ''
                           }
                        </td>

                        <td>{arModel.ar_placement === "null" ? null : <span>{arModel.ar_placement}</span>}</td>
                        <td>
                          <select
                            onChange={(e) => {
                              this.chngStatus(e, arModel.id);
                            }}
                            className={
                              arModel.status === "active"
                                ? "btn btn-sm btn-success"
                                : "btn btn-sm btn-danger"
                            }
                            id="status"
                            value={arModel.status}
                            name={"status-" + arModel.id}
                          >
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </select>
                        </td>
    
                        <td>
                        <a
                            href={"https://kre83d.com/modalviewer?modelId=" +btoa(arModel.id.toString())}
                            className="btn btn-sm btn-success"
                            target="_blank"
                          > 
                          <FontAwesomeIcon icon={"eye"} />
                          </a> <Link
                            to={"/update/arModel/" + arModel.id}
                            className="btn btn-sm btn-info"
                          > 
                          <FontAwesomeIcon icon={"pencil-alt"} />
                          </Link> <button
                            onClick={() => {
                              this.destroyarModel(arModel.id);
                            }}
                            className="btn btn-sm btn-warning"
                          >
                            <FontAwesomeIcon icon={"trash"} color="#fff" />
                          </button>
                        </td>
                      </tr>
                    ))}
                    {this.state.empty ? (
                      this.state.arModels.length === 0 ? (
                        <h3>Data not found</h3>
                      ) : null
                    ) : null}
                  </tbody>
                </table>
                <div className="col-md-3">
                  <button
                    className="btn"
                    disabled={this.state.prevPageUrl ? false : true}
                    style={{ opacity: this.state.prevPageUrl ? 1 : 0.5 }}
                    onClick={this.fetchPrevPage}
                  >
                    Prev
                  </button>
                  <button
                    className="btn"
                    onClick={this.fetchNextPage}
                    disabled={this.state.nextPageUrl ? false : true}
                    style={{ opacity: this.state.nextPageUrl ? 1 : 0.5 }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
    }
}
