import Axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { API } from "../../Constants/Constant";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Notification from "../Notification";

export default class UpdateAboutUs extends Component {
  state={
    aboutId:this.props.match.params.id,
    title:'',
    description:'',
    showNoti:false,
    notiTitle:'',
    notiColor:'',
    loader: false,
}
  handleChange = (e) =>{
    this.setState({
      [e.target.name] :e.target.value}, ()=>{
    })
  }
  updateAboutUs =(e)=>{
    e.preventDefault();
    this.setState({loader: true})
    var formData = new FormData();
    formData.append('title',this.state.title);
    formData.append('description',this.state.description);

    Axios.post(API.postURLs.updateAboutUs + this.state.aboutId,formData).then(res =>{
      if(res.data.status ==='success'){
      this.setState({title:'',description:'',showNoti: true, notiTitle: 'Updated Successfully', notiColor: 'green', loader: false});
      window.scroll(0,0);
      setTimeout(() => {
        this.setState({showNoti: false})
        this.props.history.goBack();
      }, 2000);
      }else{
        this.setState({
          notiTitle:res.data.message,notiColor:'red',showNoti:true, loader: false
        });
        window.scroll(0,0);
        setTimeout(() => {
          this.setState({showNoti: false})
        }, 2000);
      }
    }).catch(error =>{
      console.log(error.response.data);
    })
  }

  componentDidMount() {
    //console.log(this.props);
    Axios.get(API.getURLs.showAboutUs+this.state.aboutId).then(res=>{
      if(res.data.status === 'success'){
        //console.log(res);
        this.setState({
          title: res.data.aboutUs.title,
          description: res.data.aboutUs.description})
      }
    })
  
  }
  
  render() {
    return (
      <div>
         {
          this.state.showNoti ?
          <Notification title={this.state.notiTitle} bgColor={this.state.notiColor}/>:null

        }
        <div className="card">
          <div className="cardHeader">
            <h3>Add About Us</h3>
          </div>
        </div>
        <div className="card">
          <form onSubmit={this.updateAboutUs}>
            <div className="form-group col-md-6">
              <label htmlFor="title">title:</label>
              <input
                type="title"
                className="form-control"
                id="title"
                placeholder="Enter title"
                name="title"
                onChange={this.handleChange}
                value={this.state.title}
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="description">Description:</label>
              <CKEditor
                    editor={ClassicEditor}
                    data={this.state.description}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            ////console.log({ data });
                            this.setState({
                              description: data
                            });
                          }}
                        />
            </div>
            <div className="form-group col-md-6">
            {this.state.loader ? <p>Please wait while we updating your files.</p>:null}
              <button type="submit" className="btn btn-success">
              {this.state.loader ? 'Loading...':'Submit'}
              </button> <Link to="/aboutUs" className="btn btn-danger">
                Cancel
              </Link>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
